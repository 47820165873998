/**
 * Created by yudawind on 03.09.14.
 */

var mymodf = document.getElementById("top");

var moderr1 = document.getElementById('myModal');
var moderr2 = document.getElementById('myModal2');
var moderr3 = document.getElementById('myModal3');
var moderr4 = document.getElementById('myModal4');

if (document.getElementById('myModal') || document.getElementById('myModal2') || document.getElementById('myModal3') || document.getElementById('myModal4')) {
    if (document.getElementById('myModal').getElementsByClassName('alert-error')[0]
        ||
        document.getElementById('myModal2').getElementsByClassName('alert-error')[0]
        ||
        document.getElementById('myModal3').getElementsByClassName('alert-error')[0]
        ||
        document.getElementById('myModal4').getElementsByClassName('alert-error')[0]) {
        mymodf.className += " error";
    }
    else if (moderr1.getElementsByClassName('alert-success')[0]
        ||
        moderr2.getElementsByClassName('alert-success')[0]
        ||
        moderr3.getElementsByClassName('alert-success')[0]
        ||
        moderr4.getElementsByClassName('alert-success')[0]) {
        mymodf.className += " awsuccess";
    }
}

var zakerr1 = document.getElementById('zakaz1');
var zakerr2 = document.getElementById('zakaz2');

console.log(zakerr1);
if(zakerr1){
    if (zakerr1.getElementsByClassName('alert')[0]) {
        var zak_er1 = zakerr1.getElementsByClassName('alert-error');
        var zak_er3 = zakerr1.getElementsByClassName('alert-success');
        if (zak_er1[0]) {
            zakerr1.getElementsByTagName('button')[0].className += " error";
        }
        else if (zak_er3[0]) {
            mymodf.className += " awsuccess";
        }
    }
}

if(zakerr2){
    if (zakerr2.getElementsByClassName('alert')[0]) {
        var zak_er2 = zakerr2.getElementsByClassName('alert-error');
        var zak_er4 = zakerr2.getElementsByClassName('alert-success');
        if (zak_er2[0]) {
            zakerr2.getElementsByTagName('button')[0].className += " error";
        }
        else if (zak_er4[0]) {
            mymodf.className += " awsuccess";
        }
    }
}


var ozerr1 = document.getElementById('ozozv');

if (ozerr1.getElementsByClassName('alert')[0]) {
    var oz_er1 = ozerr1.getElementsByClassName('alert-error');
    var oz_er2 = ozerr1.getElementsByClassName('alert-success');
    if (oz_er1[0]) {
        mymodf.className += " error";
    }
    else if (oz_er2[0]) {
        mymodf.className += " awsuccess";
    }
}


